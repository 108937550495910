import * as React from 'react'

export const MenuContext = React.createContext({
	isActive: false,
	toggleIsActive: ()=> {}
})

export const MenuContextProvider:React.FC = ({children}) => {
	const [isActive, setIsActive] = React.useState(false)

	const value = React.useMemo(()=>{
		const toggleIsActive = ()=>{
			setIsActive(!isActive)
		}

		return {
			isActive: isActive,
			toggleIsActive
		}
	}, [isActive])

	return (
		<MenuContext.Provider value={value}>
			{children}
		</MenuContext.Provider>
	)
}

export const useMenuContext = ()=>React.useContext(MenuContext)
