import * as React from 'react'

interface Props{
	className?: string;
}

const IconLinkedin:React.FC<Props> = ({className})=>{
	return (
		<svg className={className} viewBox="0 0 383 383" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M348.09 0.256836H35.1998C16.1158 0.256836 0.644775 15.7278 0.644775 34.8118V347.701C0.644775 366.786 16.1158 382.257 35.1998 382.257H348.089C367.174 382.257 382.645 366.786 382.645 347.701V34.8118C382.645 15.7278 367.174 0.256836 348.09 0.256836ZM118.852 330.101C118.852 335.655 114.35 340.157 108.796 340.157H65.9898C60.4358 340.157 55.9338 335.655 55.9338 330.101V150.66C55.9338 145.106 60.4358 140.604 65.9898 140.604H108.796C114.35 140.604 118.852 145.106 118.852 150.66V330.101ZM87.3928 123.689C64.9338 123.689 46.7268 105.482 46.7268 83.0228C46.7268 60.5638 64.9338 42.3568 87.3928 42.3568C109.852 42.3568 128.059 60.5638 128.059 83.0228C128.059 105.482 109.853 123.689 87.3928 123.689ZM342.555 330.911C342.555 336.017 338.415 340.157 333.309 340.157H287.375C282.269 340.157 278.129 336.017 278.129 330.911V246.743C278.129 234.187 281.812 191.722 245.316 191.722C217.007 191.722 211.265 220.788 210.112 233.832V330.911C210.112 336.017 205.973 340.157 200.866 340.157H156.44C151.334 340.157 147.194 336.017 147.194 330.911V149.85C147.194 144.744 151.334 140.604 156.44 140.604H200.866C205.972 140.604 210.112 144.744 210.112 149.85V165.505C220.609 149.752 236.209 137.593 269.424 137.593C342.976 137.593 342.555 206.309 342.555 244.065V330.911Z" fill="currentColor"/>
		</svg>
	)
}

export default IconLinkedin
