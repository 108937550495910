import { Link } from 'gatsby';
import * as React from 'react';
import socialmedias from '../content/socialmedias';
import IconCodepen from './IconCodepen';
import IconGithub from './IconGithub';
import IconLinkedin from './IconLinkedin';

// Types
interface SocialLink {
	title: string;
	url: string;
	icon: JSX.Element
}

// Data
const SOCIAL_LINKS = [
	//// {
	//// 	title: 'Instagram',
	//// 	url: socialmedias.instagram.url,
	//// 	icon: (<IconInstagram className='h-6'/>)
	//// },
	{
		title: socialmedias.codepen.title,
		url: socialmedias.codepen.url,
		icon: (<IconCodepen className='h-6'/>)
	},
	{
		title: socialmedias.github.title,
		url: socialmedias.github.url,
		icon: (<IconGithub className='h-6'/>)
	},
	{
		title: socialmedias.linkedin.title,
		url: socialmedias.linkedin.url,
		icon: (<IconLinkedin className='h-5'/>)
	},
] as SocialLink[]

// Markup
const Footer:React.FC = ()=>{
	return (
		<footer className='wrapper flex-col sm:flex-row flex items-center justify-between mt-10 pb-16 sm:pb-24'>
			<Link className='font-semibold' to="/" title='Homepage'>
				Carlos Anaya
			</Link>
			<ul className='flex items-center mt-8 sm:mt-0'>
				{SOCIAL_LINKS.map(({title, icon, url})=>(
					<li key={title} className='first:ml-0 ml-8'>
						<a href={url} target="_blank" className='opacity-60 hover:opacity-100 transition duration-200'>
							{icon}
						</a>
					</li>
				))}
			</ul>
		</footer>
	)
}

export default Footer
