export default {
	instagram: {
		title: 'Instagram',
		url: 'https://www.instagram.com/carlosanaya.01'
	},
	codepen: {
		title: 'CodePen',
		url: 'https://codepen.io/carlosanaya01'
	},
	github: {
		title: 'Github',
		url: 'https://github.com/carlosanaya01'
	},
	linkedin: {
		title: 'LinkedIn',
		url: 'https://www.linkedin.com/in/carlosanaya01/'
	},
}
