import * as React from 'react'
import {WrapPageElementNodeArgs} from 'gatsby'
import Navbar from './components/navbar'
import { MenuContextProvider } from './contexts/menu'

const Wrapper:React.FC = ({children})=>{
	return (
		<MenuContextProvider>
			<div>
				<Navbar/>
				{children}
			</div>
		</MenuContextProvider>
	)
}

const wrapPage = ({ element, props }:WrapPageElementNodeArgs)=>{
	return <Wrapper {...props}>{element}</Wrapper>
}

export default wrapPage
