import * as React from 'react'
import { Link } from 'gatsby';
import { useMenuContext } from '../contexts/menu'
import Footer from './footer';
import { openEmailLink } from '../util/email';

interface Item {
	title: string,
	to?: string,
	onclick?: any
}

const ITEMS = [
	{
		title: 'My projects',
		to: '/#projects'
	},
	{
		title: 'About me',
		to: '/about'
	},
	{
		title: 'Let’s chat',
		onclick: openEmailLink
	},
] as Item[]

const Arrow:React.FC = ()=>(
	<svg className='h-6 mr-5 transition opacity-0 hidden sm:block sm:group-hover:opacity-100' viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path opacity="0.5" d="M21.8992 26.8431L25.0291 29.973L40 15.0001L25.0291 0.0270996L21.8992 3.15702L31.5086 12.7646H0V17.2332H31.5108L21.8992 26.8431Z" fill="currentColor"/>
	</svg>
)

interface ItemLinkProps extends Item{
	className: string
}
const ItemLink:React.FC<ItemLinkProps> = ({to, onclick, title, className=''})=>{
	const {toggleIsActive} = useMenuContext()
	if (to) {
		return (
			<Link
				to={to}
				className={className}
				onClick={toggleIsActive}>
				<Arrow />
				{title}
			</Link>
		)
	}

	return (
		<button
			className={className}
			onClick={()=>{onclick(); toggleIsActive()}}>
			<Arrow />
			{title}
		</button>
	)
}

const Menu:React.FC = ()=>{
	const {isActive} = useMenuContext()
	return (
		<div className={
				'z-10 fixed inset-0 h-fill-available overflow-scroll flex flex-col'
				+ ' supports-backdrop:bg-white/50 bg-white backdrop-blur-2xl backdrop-saturate-150'
				+ ' transition-visibility duration-500'
				+ (isActive ? ' visible opacity-100' : ' invisible opacity-0')
			}>
			<ul className='wrapper flex-grow flex justify-center flex-col pt-28 sm:pt-14'>
				{ITEMS.map((item)=>(
					<li key={item.title}>
						<ItemLink
							{...item}
							className={
								'text-3xl sm:text-5xl font-extrabold'
								+ ' py-4 sm:py-6 flex items-center justify-center sm:justify-start group appearance-none w-full'
								+ ' sm:hover:underline sm:hover:pl-4 transition-all'
							}/>
					</li>
				))}
			</ul>
			<Footer />
		</div>
	)
}

export default Menu
