import * as React from 'react'

const lineBaseClass = 'block absolute h-[2px] bg-dark transition-all ease-in-out duration-300 rounded'

interface Props {
	isOpen: boolean,
	onClick: React.MouseEventHandler
}

const HamburguerButton: React.FC<Props> = ({isOpen, onClick})=>{
	return (
		<button
			className='w-6 h-4 relative'
			onClick={onClick}
			title="menu">
			<span className={`${lineBaseClass} ${isOpen ? 'opacity-0 top-1/2 w-0 left-1/2' : 'w-full top-0 left-0'}`}/>
			<span className={`${lineBaseClass} w-full top-1/2 -translate-y-1/2 ${isOpen ? 'rotate-45' : 'rotate-0'}`}/>
			<span className={`${lineBaseClass} w-full top-1/2 -translate-y-1/2 ${isOpen ? '-rotate-45' : 'rotate-0'}`}/>
			<span className={`${lineBaseClass} ${isOpen ? 'opacity-0 bottom-1/2 w-0 left-1/2' : 'w-full bottom-0 left-0'}`}/>
		</button>
	)
}

export default HamburguerButton
