import * as React from 'react'

interface Props{
	className?: string;
}

const IconCodepen:React.FC<Props> = ({className})=>{
	return (
		<svg className={className} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.6484 6.2749L9.42188 0.790527C9.14062 0.614746 8.82422 0.614746 8.54297 0.790527L0.316406 6.2749C0.105469 6.41553 0 6.66162 0 6.90771V12.3921C0 12.6733 0.105469 12.9194 0.316406 13.0601L8.54297 18.5444C8.82422 18.7202 9.14062 18.7202 9.42188 18.5444L17.6484 13.0601C17.8594 12.9194 18 12.6733 18 12.3921V6.90771C18 6.66162 17.8594 6.41553 17.6484 6.2749ZM9.77344 2.8999L15.8203 6.90771L13.1133 8.73584L9.77344 6.48584V2.8999ZM8.22656 2.8999V6.48584L4.85156 8.73584L2.14453 6.90771L8.22656 2.8999ZM1.54688 8.38428L3.48047 9.6499L1.54688 10.9507V8.38428ZM8.22656 16.4351L2.14453 12.3921L4.85156 10.5991L8.22656 12.8491V16.4351ZM9 11.478L6.25781 9.6499L9 7.82178L11.707 9.6499L9 11.478ZM9.77344 16.4351V12.8491L13.1133 10.5991L15.8203 12.3921L9.77344 16.4351ZM16.4531 10.9507L14.4844 9.6499L16.4531 8.38428V10.9507Z" fill="currentColor"/>
		</svg>
	)
}

export default IconCodepen
